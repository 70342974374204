var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.sliceLength)?_c('div',{staticClass:"row m-0"},_vm._l((_vm.connectors.slice(0, 8)),function(platform,i){return _c('div',{key:i,staticClass:"connector",class:{
            active: _vm.findSelectedConnectors(platform) || _vm.addActiveClass,
        },on:{"click":function($event){return _vm.addPlatform(platform, _vm.title)}}},[_c('div',{staticClass:"row w-100 m-0",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"platform row",staticStyle:{"width":"90%","margin-left":"1px","align-items":"center"}},[_c('div',{staticClass:"image d-flex justify-center p-3"},[_c('img',{staticStyle:{"object-fit":"contain"},attrs:{"src":_vm.getImage(platform.image),"alt":platform.image,"height":"24px","width":"24px"}})]),_c('div',{staticClass:"text darkColorText font-medium ProximaNovaBold"},[_vm._v(" "+_vm._s(platform.name)+" ")])]),_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"10%","align-items":"center"}},[_c('div',{staticClass:"action font-medium"},[(_vm.findSelectedConnectors(platform) || _vm.addActiveClass)?_c('i',{staticClass:"fas fa-times"}):_c('i',{staticClass:"fas fa-plus"})])])])])}),0):_vm._e(),(_vm.connectorModule)?_c('div',{staticClass:"row m-0"},_vm._l((_vm.connectors.slice(0, 8)),function(platform,i){return _c('div',{key:i,staticClass:"connector",class:{
            active: _vm.findSelectedConnectors(platform) || _vm.addActiveClass,
        },on:{"click":function($event){return _vm.addPlatform(platform, _vm.title)}}},[_c('div',{staticClass:"row w-100 m-0",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"platform row",staticStyle:{"width":"90%","margin-left":"1px","align-items":"center"}},[_c('div',{staticClass:"image d-flex justify-center p-3"},[_c('img',{staticStyle:{"object-fit":"contain"},attrs:{"src":_vm.getImage(platform.image),"alt":platform.image,"height":"24px","width":"24px"}})]),_c('div',{staticClass:"text darkColorText font-medium ProximaNovaBold"},[_vm._v(" "+_vm._s(platform.name)+" ")])]),_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"10%","align-items":"center"}},[_c('div',{staticClass:"action font-medium"},[(_vm.findSelectedConnectors(platform) || _vm.addActiveClass)?_c('i',{staticClass:"fas fa-check"}):_c('i',{staticClass:"fas fa-plus"})])])])])}),0):_c('div',{staticClass:"row m-0"},_vm._l((_vm.connectors),function(platform,i){return _c('div',{key:i,staticClass:"connector",class:{
            active: _vm.findSelectedConnectors(platform) || _vm.addActiveClass,
        },on:{"click":function($event){return _vm.addPlatform(platform, _vm.title)}}},[_c('div',{staticClass:"row w-100 m-0",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"platform row",staticStyle:{"width":"90%","margin-left":"1px","align-items":"center"}},[_c('div',{staticClass:"image d-flex justify-center p-3"},[_c('img',{staticStyle:{"object-fit":"contain"},attrs:{"src":_vm.getImage(platform.image),"alt":platform.image,"height":"24px","width":"24px"}})]),_c('div',{staticClass:"text darkColorText font-medium ProximaNovaBold"},[_vm._v(" "+_vm._s(platform.name)+" ")])]),_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"10%","align-items":"center"}},[_c('div',{staticClass:"action font-medium"},[(_vm.findSelectedConnectors(platform) || _vm.addActiveClass)?_c('i',{staticClass:"fas fa-times"}):_c('i',{staticClass:"fas fa-plus"})])])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }